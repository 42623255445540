/* css for desktop view  */
.faq-wrapper{
    background-color: #fff;
    width: 60vw;
    border-radius: 2.335px;
    position: absolute;
    margin-left: 12em;
    box-shadow: 0px 0px 12px black;
    z-index: 100;
    margin-top: 5em;
}

.faq-title{
    background-color: black;
    color: #fff;
    height: 51.354px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.faq-header p{
    font-family: Lato;
    font-size: 20.649px;
    font-style: normal;
    font-weight: 800;

}
.faq-accordion1-head{
    display: flex;
    gap: 20px;
    height: 50px;
    border-radius: 5.162px;
    align-items: center;
    justify-content: center;
    
    
}

.faq-product-name{
    color: #111;

    text-align: center;
    font-family: Lato;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.faq-accordion1{
    background-color: #FAC710;
    width: 40vw;
    margin: 1em auto;
    border-radius: 5.162px;
    height: 50px;
    overflow: hidden;
    transition: height 0.35s ease-in-out;
}

.faq-accordion1.active{
    height: 460px;
}

.faq-accordion-text img{
    width: 60%;
    object-fit: cover;
    align-self: center;
    margin-left: 7em;
    
}

.faq-accordion-text p{
    width: 310px;
    margin: 0 auto;
    font-family: Lato;
    margin-top: 50px;
    margin-bottom: 20px;

    font-style: normal;
    font-weight: 400;
}
.faq-accordion-text-ul p{
    width: 310px;
    margin-top: 40px;
    margin-left: 40px;
    font-family: Lato;
    font-style: normal;
    font-weight: 400;
    margin-top: 30px;
    padding: 50px;
}
.faq-accordion-text-ul img{
    float: right;
    margin-top: 58px;
}

p.accord2{
    width: 340px;
}
p.accord4{
    width: 375px;
}

/* solve the overflow issue */
.main .myAppcontainer{
    overflow-y: hidden;
    position: unset !important;
}

.plusOrminus{
    color: #FFF;
    position: absolute;
    right: 20%;
    text-align: center;
    font-family: Lato;
    font-size: 20.493px;
    font-weight: 600;
    line-height: normal;
    background-color: #111;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    cursor: pointer;
    margin-left: 358px;


}

.faq-close{
    width: 20vw;
    height: 50px;
    background-color: black;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 1em auto;
    border-radius: 2.332px;
    cursor: pointer;
    transition: background-color 0.5s ease;
}
.faq-close:hover,
.faq-close:focus{
    background-color: #FAC710;

}

.faq-close p{
    color: #FFF;

    text-align: center;
    font-family: Lato;
    font-size: 1.2em;
    font-style: normal;
    font-weight: 700;
}


/* end of css for desktop view */

/* css for mobile view  */
@media  (max-width: 512px){
    .faq-header{
        height: 41.354px;
    }
    .faq-header p{
        font-size: 10.649px;

    }
    .faq-accordion1-head{
        height: 40px;
        width: 45vw;
    }

    .faq-product-name {
        font-size: 13.5px;
        padding-left: 0.22em;
    }

    .plusOrminus{
        font-size: 15.493px;
        /* right: 18%; */
        width: 20px;
        height: 20px;
    }

    .faq-close{
        height: 25px;

    }
    .faq-accordion-text img {
       
        /* align-self: center; */
        margin-left: 3em;
    }

    p.accord2 {
        width: 205px;
        font-size: 0.85em;
    }
    
    p.accord4 {
        width: 212px;
        font-size: 0.89em;
    }

    .faq-accordion-text p {
        width: 210px;
        
    }
    .faq-accordion1.active {
        height: 300px;
    }

    .faq-close p {
    font-size: 0.89em;
}

    .whyxcelsx-container{
        margin-top: -1em;
        margin-left: 2em;
        width: 80vw;
    }
    .faq-accordion1{
        height: 35px;
        width: 61vw;
    }
}
/* css for mobile view ends here */