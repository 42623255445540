.shw {
  translate: 0 0
}

.hide {
  translate: 0 650px
}

.showHid {
  display: flex
}

.closeHid {
  display: none
}

.waitlist-overlay {
  background-color: rgba(0, 0, 0, .717);
  display:block;
  height: 100%;
  justify-content: center;
  padding-top: 3%;
  position: fixed  !important;
  width: 100%;
  z-index: 2;
}
div.waitlist-container{
  animation: slide-in 0.5s ease-in-out forwards;
  position: absolute;
}
.waitlist-overlay .waitlist-container {
  background-color: #fff;
  display: flex;
  justify-self: center !important;
  height: 550px;
  position: relative;
  width: 85%;
  margin-left: 24%;
}
@keyframes slide-in {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0%);
  }
}
.waitlist-overlay .waitlist-container .closeBtn {
  display: flex;
  position: absolute;
  right: 0;
  top: 0
}

.waitlist-overlay .waitlist-container .closeBtn a {
  display: flex;
  height: 35px;
  text-decoration: none;
  width: 35px
}

.waitlist-overlay .waitlist-container .closeBtn a img {
  display: flex;
  height: 100%;
  width: 100%
}

.waitlist-overlay .waitlist-container .waitlist-left {
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 5% 2% 0;
  width: 50%
}

.waitlist-overlay .waitlist-container .waitlist-left .right-caption {
  display: flex;
  flex-direction: column;
  width: 50%
}

.waitlist-overlay .waitlist-container .waitlist-left .right-caption p {
  color: #111;
  display: flex;
  font-family: Lato;
  font-size: 24px;
  font-weight: 800;
  width: 100%
}

.waitlist-overlay .waitlist-container .waitlist-left .right-caption span {
  background-color: #fac710;
  display: flex;
  height: 5px;
  width: 40%
}

.waitlist-overlay .waitlist-container .waitlist-left .right-img {
  align-items: center;
  display: flex;
  height: 200px;
  justify-content: center;
  margin-top: 10%;
  padding: 10px;
  width: 250px
}

.waitlist-overlay .waitlist-container .waitlist-left .right-img img {
  display: flex;
  height: 25vw;
  width: 35vw;
}

.waitlist-overlay .waitlist-container .waitlist-right {
  display: flex;
  flex-direction: column;
  padding-top: 10%;
  width: 50%
}

.waitlist-overlay .waitlist-container .waitlist-right .captions {
  display: flex;
  flex-direction: column;
  margin-top: -2vw;
  margin-bottom: 2vw;
  
}

.waitlist-overlay .waitlist-container .waitlist-right .captions h4 {
  color: #111;
  font-family: Lato
}

.waitlist-overlay .waitlist-container .waitlist-right .captions p {
  color: #111;
  font-family: Lato;
  font-weight: 500;
  opacity: .6
}

.waitlist-overlay .waitlist-container .waitlist-right .captions #loader {
  color: red;
  font-size: 12px;
  margin-top: 10px
}

.waitlist-overlay .waitlist-container .waitlist-right .waitlist-form {
  display: flex;
  flex-direction: column;
  margin-top: 5%;
  width: 90%
}

.waitlist-overlay .waitlist-container .waitlist-right .waitlist-form .form-row {
  display: flex;
  gap: 5%;
  margin: 10px 0;
  width: 100%
}

.waitlist-overlay .waitlist-container .waitlist-right .waitlist-form .form-row select {
  background-color: #fff;
  border: 2px solid #111;
  border-radius: 5px;
  display: flex;
  height: 40px;
  padding: 2% 3% 2% 1%;
  width: 43%
}

.waitlist-overlay .waitlist-container .waitlist-right .waitlist-form .form-row input {
  background-color: #fff;
  border: 2px solid #111;
  border-radius: 5px;
  display: flex;
  height: 39px;
  padding: 5px;
  width: 43%
}

.waitlist-overlay .waitlist-container .waitlist-right .waitlist-form-btn {
  align-self: center;
  display: flex;
  margin-top: 5%;
  width: 60%;
  margin-bottom: 6vw;
}

.waitlist-overlay .waitlist-container .waitlist-right .waitlist-form-btn a {
  align-items: center;
  background-color: #111;
  color: #fff;
  display: flex;
  justify-content: center;
  padding: 4% 20%;
  text-decoration: none;
  margin-bottom: 10px;
}
.waitlist-container{
  transition: slide-in 0.5s ease-in-out forwards;
}


@media screen and (max-width:430px) {
  .waitlist-overlay {
      background-color: rgba(0, 0, 0, .717);
      display: flex;
      height: 100%;
      justify-content: center;
      padding-top: 3%;
      position: absolute;
      width: 100%;
      z-index: 2
  }

  .waitlist-overlay .waitlist-container {
      background-color: #fff;
      display: flex;
      flex-direction: column !important;
      height: auto !important;
      position: relative;
      width: 95%
  }
.waitlist-container{
  animation: slide-in 0.5s ease-in-out forwards;
}

  .waitlist-overlay .waitlist-container .closeBtn {
      display: flex;
      position: absolute;
      right: 0;
      top: 0
  }

  .waitlist-overlay .waitlist-container .closeBtn a {
      display: flex;
      height: 35px;
      text-decoration: none;
      width: 35px
  }

  .waitlist-overlay .waitlist-container .closeBtn a img {
      display: flex;
      height: 100%;
      width: 100%
  }

  .waitlist-overlay .waitlist-container .waitlist-left {
      align-items: center;
      display: flex;
      flex-direction: column;
      padding: 5% 2% 0;
      width: 100%
  }

  .waitlist-overlay .waitlist-container .waitlist-left .right-caption {
      display: flex;
      flex-direction: column;
      width: 80%
  }

  .waitlist-overlay .waitlist-container .waitlist-left .right-caption p {
      color: #111;
      display: flex;
      font-family: Lato;
      font-size: 20px;
      font-weight: 800;
      width: 100%
  }

  .waitlist-overlay .waitlist-container .waitlist-left .right-caption span {
      background-color: #fac710;
      display: flex;
      height: 5px;
      width: 40%
  }

  .waitlist-overlay .waitlist-container .waitlist-left .right-img {
      align-items: center;
      display: flex;
      height: 150px;
      justify-content: center;
      margin-top: 10%;
      padding: 10px;
      width: 200px
  }

  .waitlist-overlay .waitlist-container .waitlist-left .right-img img {
      display: flex;
      height: 100%;
      width: 100%
  }

  .waitlist-overlay .waitlist-container .waitlist-right {
      display: flex;
      flex-direction: column;
      padding: 2%;
      width: 100%
  }

  .waitlist-overlay .waitlist-container .waitlist-right .captions {
      display: flex;
      flex-direction: column
  }

  .waitlist-overlay .waitlist-container .waitlist-right .captions h4 {
      color: #111;
      font-family: Lato
  }

  .waitlist-overlay .waitlist-container .waitlist-right .captions p {
      color: #111;
      font-family: Lato;
      font-weight: 500;
      opacity: .6
  }

  .waitlist-overlay .waitlist-container .waitlist-right .captions #loader {
      color: red;
      font-size: 12px;
      margin-top: 10px
  }

  .waitlist-overlay .waitlist-container .waitlist-right .waitlist-form {
      display: flex;
      flex-direction: column;
      margin-top: 5%;
      width: 100%
  }

  .waitlist-overlay .waitlist-container .waitlist-right .waitlist-form .form-row {
      display: block;
      gap: 5%;
      margin: 10px 0;
      width: 100%
  }

  .waitlist-overlay .waitlist-container .waitlist-right .waitlist-form .form-row select {
      background-color: #fff;
      border: 2px solid #111;
      border-radius: 5px;
      display: flex;
      height: 40px;
      padding: 2% 3% 2% 1%;
      width: 95%
  }

  .waitlist-overlay .waitlist-container .waitlist-right .waitlist-form .form-row input {
      background-color: #fff;
      border: 2px solid #111;
      border-radius: 5px;
      display: flex;
      height: 25px;
      padding: 5px;
      margin-top: 20px;
      width: 91.5%
  }

  .waitlist-overlay .waitlist-container .waitlist-right .waitlist-form-btn {
      align-self: center;
      display: flex;
      margin-top: 5%;
      width: 60%
  }

  .waitlist-overlay .waitlist-container .waitlist-right .waitlist-form-btn a {
      align-items: center;
      background-color: #111;
      color: #fff;
      display: flex;
      justify-content: center;
      padding: 7% 10%;
      text-decoration: none
  }

  .alert-waitdiv{

    top: 40% !important;
  left: 7% !important;
  width: 340px !important;
}

  .alert-waityesdiv{
    top: 40% !important;
    left: 1% !important;
    width: 370px !important;
}
}

.kontainer {
  background-color: rgba(0, 0, 0, .717);
  display: flex;
  height: 100%;
  justify-content: center;
  position: absolute;
  width: 100%;
  z-index: 2
}

.kontainer .mybox {
  background-color: #fac710;
  border-radius: 5px;
  display: flex;
  height: 240px;
  margin-top: 15%;
  position: relative;
  width: 40%
}

.kontainer .mybox .closeBtn {
  display: flex;
  position: absolute;
  right: 2%;
  top: 2%
}

.kontainer .mybox .closeBtn a {
  display: flex;
  height: 40px;
  text-decoration: none;
  width: 40px
}

.kontainer .mybox .closeBtn a img {
  display: flex;
  height: 100%;
  width: 100%
}

.kontainer .mybox .box-right {
  align-items: center;
  display: flex;
  justify-content: center;
  width: 50%
}

.kontainer .mybox .box-right img {
  display: flex;
  height: 70%;
  width: 70%
}

.kontainer .mybox .box-left {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 50%
}

.kontainer .mybox .box-left span {
  font-size: 14px;
  margin-top: 5px
}

.show {
  display: flex
}

.hide {
  display: none
}
@keyframes slide-in {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0%);
  }
}

.alert-waitdiv{
  position: fixed;
  top: 30%;
  left: 30%;
  width: 40%;
  background-color: #FAC710;
}
.alert-waityesdiv{
  position: fixed;
  top: 30%;
  left: 30%;
  width: 42%;
  padding: 0.4em;
  background-color: #FAC710;
}

.alert-waitbtn{
position: relative;
  top: -160px;
  left: 95%;
  outline: none;
  background: transparent;
  border: none;
  cursor: pointer;
  font-weight: 700;
}

