/* css for desktop view  */
.below-text{
    display: flex;
    gap: 2px;
    margin-top: 2.5em;
}

.below-text .Find-out-img{
    width: 20%;
    object-fit: contain;
    /* align-self: flex-end; */
    position: relative;
    top: 40px;
}


.whyxcelsx-container-find{
  
    background-color: #fff;
    width: 60vw;
    border-radius: 2.335px;
    position: absolute;
    right: 20%;
    box-shadow: 0px 0px 12px black;
    z-index: 100;
    margin-top: 5em;
}

.whyxcelsz-header{
    background-color: black;
    color: #fff;
    height: 51.354px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.whyxcelsz-header p{
    font-family: Lato;
    font-size: 20.649px;
    font-style: normal;
    font-weight: 800;

}
.whyxcelsz-accordion1-head{
    display: flex;
    gap: 20px;
    height: 50px;
    border-radius: 5.162px;
    align-items: center;
    justify-content: center;
    
    
}

.whyxcelsz-product-name{
    color: #111;

    text-align: center;
    font-family: Lato;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.whyxcelsz-accordion1-f{
    background-color: #FAC710;
    width: 40vw;
    margin: 1em auto;
    border-radius: 5.162px;
    height: 50px;
    overflow: hidden;
    transition: height 0.35s ease-in-out;
}

.whyxcelsz-accordion1-f.active{
    height: 420px;
}

.whyxcelsz-accordion-text-f {
    margin-left: 5em;
    margin-top: 1.5em;
}

.whyxcelsz-accordion-text-f img{
    width: 60%;
    object-fit: cover;
    align-self: center;
    margin-left: 7em;
    
}

.whyxcelsz-accordion-text-f p{
    width: 390px;
    font-family: Lato;
    font-style: normal;
    font-weight: 400;
    /* margin-left: 5em; */
    margin-top: 0.5em;
}

p.accord2{
    width: 340px;
}
p.accord4{
    width: 375px;
}

/* solve the overflow issue */
.main .myAppcontainer{
    overflow-y: hidden;
    position: unset !important;
}

.plusOrminus{
    color: #FFF;
    position: absolute;
    right: 20%;
    text-align: center;
    font-family: Lato;
    font-size: 20.493px;
    
    font-weight: 600;
    line-height: normal;
    background-color: #111;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    cursor: pointer;


}

.whyxcelsz-close{
    width: 20vw;
    height: 50px;
    background-color: black;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 1em auto;
    border-radius: 2.332px;
    cursor: pointer;
    transition: background-color 0.5s ease;
}
.whyxcelsz-close:hover,
.whyxcelsz-close:focus{
    background-color: #FAC710;

}
.whyxcelsz-close:hover div.whyxcelsz-close p,
.whyxcelsz-close:focus div.whyxcelsz-close p{
    color: #111 !important ;
}

.whyxcelsz-close p{
    color: #FFF;

    text-align: center;
    font-family: Lato;
    font-size: 1.2em;
    font-style: normal;
    font-weight: 700;
}

li::marker{
    font-size: 20px;
    
}
li{
    margin-left: 2.5em;
}


/* end of css for desktop view */

/* css for mobile view  */
@media  (max-width: 512px){
    .whyxcelsz-header{
        height: 41.354px;
    }
    .whyxcelsz-header p{
        font-size: 10.649px;

    }
    .whyxcelsz-accordion1-head{
        height: 40px;
        width: 55vw;
    }

    .whyxcelsz-product-name {
        font-size: 12.5px;
        padding-left: 0.3em;
    }

    .plusOrminus{
        font-size: 15.493px;
        right: 11%;
        width: 20px;
        height: 20px;
    }

    .whyxcelsz-close{
        height: 25px;

    }
    .whyxcelsz-accordion-text-f img {
       
        /* align-self: center; */
        margin-left: 3em;
    }

    p.accord2 {
        width: 205px;
        font-size: 0.85em;
    }
    
    p.accord4 {
        width: 212px;
        font-size: 0.89em;
    }

    .whyxcelsz-accordion-text-f p {
        width: 252px;
        
    }
    .whyxcelsz-accordion1.active {
        height: 300px;
    }

    .whyxcelsz-close p {
    font-size: 0.89em;
}

    .whyxcelsx-container-find{
        margin-top: 53em;
        margin-right: -40px;
        width: 80vw;
    }
    .whyxcelsz-accordion1-f{
        height: 35px;
        width: 70vw;
    }
    .whyxcelsz-accordion-text-f {
        margin-left: 1em;
        margin-top: 1.5em;
        font-size: 0.8em;
    }
    .below-text .Find-out-img {
        top: 69.5px;
        right: 30px;
    }

    ul h2{
        font-size: 1.15em;
        width: 199px;
    
    }
}

@media screen and (min-width:1178px) and (min-height:399px)
  {
.whyxcelsz-accordion-text-f{
    margin-left: 1.1em;
}

ul h2{
    font-size: 1.34em;
}
}

@media screen and (min-width: 1221px) and (min-height: 399px){
    ul h2{
        font-size: 1.21em;

    }
}
@media screen and (min-width: 1725px) and (min-height: 399px){
    .whyxcelsz-accordion-text-f {
        margin-left: 6.5em;
    }
}
/* css for mobile view ends here */