.shw {
    translate: 0 0
  }
  
  .hide {
    translate: 0 650px
  }
  
  .showHid {
    display: flex
  }
  
  .closeHid {
    display: none
  }
  
  .ctaScreen-overlay {
    background-color: rgba(0, 0, 0, .717);
    display: flex;
    /* display:block; */
    height: 100%;
    justify-content: center;
    padding-top: 3%;
    position: fixed  !important;
    width: 100%;
    z-index: 2;
  }
  div.ctaScreen-container{
    animation: slide-in 0.5s ease-in-out;
    position: absolute;
  }
  .ctaScreen-overlay .ctaScreen-container {
    background-color: #fff;
    display: flex;
    flex-direction: column;
    justify-self: center;
    height: auto;
    position: relative;
    width: 85%;
    padding: 2rem 1rem;
    overflow-x: hidden;
    overflow-y: scroll;

  }
  @keyframes slide-in {
    from {
      transform: translateX(100%);
    }
    to {
      transform: translateX(0%);
    }
  }
  .ctaScreen-overlay .ctaScreen-container .closeBtn {
    display: flex;
    position: absolute;
    right: 0;
    top: 0
  }
  
  .ctaScreen-overlay .ctaScreen-container .closeBtn a {
    display: flex;
    height: 35px;
    text-decoration: none;
    width: 35px
  }
  .ctaScreen-container{
    transition: slide-in 0.5s ease-in-out forwards;
  }
  .ctaScreen-container h1{
    margin-bottom: 8px;
  }
  .ctaScreen-container p{
    font-size: 1rem;
    font-weight: 600;
  }
  .ctaScreen-container span{
    width: 75%;
    font-size: 1.1rem;
  }
  .ctaScreen-container .yellow-bar{
    background-color: #fac710;
    display: flex;
    width: 17%;
    padding: 0.18rem;
    margin-bottom: 8px;
    margin-top: 4px;
   
  }


  .cards-container{
    margin-top: 2rem;
  }

  .cards-container .container-header{
    width: 100%;
    background: #FAC710;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 1.2rem;
    font-weight: 600;
    padding: 1rem;
  }


  .cards-container .container-header .filter-selectors{
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    margin-top: 1.5rem;
  }
  .cards-container .container-header .filter-selectors .all-solutions{
    width:  20%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 2rem;
    border-radius: 3px;
    background: #111111;
    color: #fff;
  }
  .cards-container .container-header .filter-selectors select{
    width: 20% !important;
    height: 2rem;
    border-radius: 3px;
    border: 2px solid #111111;
    font-size: 1rem;
    font-weight: 600;
    /* padding: 15px 50px 15px 50px; */
  }


  .project-cards{
    margin: 2rem auto;
    display: grid;
    align-items: center;
    justify-content: center;
    grid-template-columns: repeat(3,1fr);
    grid-template-rows: 1fr 1fr;
    gap: 1rem;
    row-gap: 2rem
  }
  .project-cards .card0 {
    background-color: #fff;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow-x: hidden;
    max-width: 300px;
    justify-self: center;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    
  }
  
  .project-cards .card0 .caption {
    align-items: center;
    background-color: #fac710;
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100px !important;
    padding: 1rem 0;
  }
  
  .project-cards .card0 .caption p {
    display: flex;
    font-size: 20px;
    font-weight: 600;
    padding: 10px;
    text-align: center;
    width: 80%
  }
  
  .project-cards .card0 .mid {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 120px;
    width: 100%
  }

  .project-cards .countdown-row {
    align-items: center;
    display: flex;
    gap: 15px;
    justify-content: center;
    width: 90%
  }
  
  .project-cards .countdown-row .countdown-col {
    align-items: center;
    display: flex;
    flex-direction: column
  }
  
  .project-cards .countdown-row .countdown-col span {
    font-weight: 600
  }
  
  .project-cards .card0 .mid p {
    font-size: 24px;
    margin: 15px 0
  }
  
  .project-cards .card0 .foot {
    align-items: center;
    display: flex;
    /* height: 100%; */
    margin: 2rem auto;
    justify-content: center;
    width: 100%
  }
  
  .project-cards .card0 .foot a {
    align-items: center;
    background-color: #111;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    padding: 10px;
    text-decoration: none
  }
  
  .project-cards .card0 .foot a span {
    color: #fff;
    display: flex;
    text-align: center;
    width: 70%
  }

  .pagination{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .pagination span{
    padding: .3rem .5rem;
    font-size: 1.4rem;
    font-weight: 600;
    border-radius: 3px;
    margin: .5rem;
    cursor: pointer;
  }

  .pagination span.prev{
    background: #FAC710;
    color: #111;
    border: 1px solid #111;
  }
  .pagination span.next{
    background-color: #111111;
    color: #fff;
    border: 1px solid #FAC710;
  }


  @media screen and (max-width: 735px){
    .cards-container .container-header .filter-selectors{
      display: flex;
      flex-direction: column !important;
    }
    .cards-container .container-header .filter-selectors select{
      width: 100% !important;
      margin: 8px auto;
    }
    .cards-container .container-header .filter-selectors .all-solutions{
      width: 100% !important;
    }
    .project-cards {
      margin: 2rem auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      grid-template-columns: repeat(3,1fr);
      grid-template-rows: 1fr 1fr;
      gap: 1rem;
      row-gap: 2rem;
  }
  .ctaScreen-container span{
    width: 100%;
    font-size: 1rem;
  }
}

@media screen and (max-width: 950px){
  .project-cards {
    margin: 2rem auto;
    align-items: center;
    justify-content: center;
    grid-template-columns: repeat(2,1fr);
    grid-template-rows: 1fr 1fr 1fr;
    gap: 1rem;
    row-gap: 2rem;
}
}