section.modal-main{
  position: fixed; /* Stay in place */
  z-index: 100; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: hidden; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
  font-size: 1rem;
  transition:  0.5s ease-in-out;
  
}

span.clsBtn{
  font-size: 2em;
  position:absolute;
  z-index: 300;
  font-weight: 700;
  top: 1%;
  right: 4%;
}

span.clsBtn:hover,
span.clsBtn:focus{
  color:#FAC710;
  cursor: pointer;
}

div.modal-content{
  background-color: #fefefe;
  position: absolute;
  height: 98.5vh;
  right: 0;
  padding: 15px;
  border: 1px solid #888;
  width: 70%;
  top: 1%;
  right: 0;
  animation: slide-in 0.5s ease-in-out forwards;
  margin-left: 24% !important;
  
}

/* .modal-main.active {
  right: 0;
} */

@keyframes slide-in {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(-20%);
  }
}


/* .close {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
  }
  
  .close:hover,
  .close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
  } */
  div.side-bar{
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: -1.2em;
  }
  div.clickable{
    width: 290px;
    height: 50px;
    border: 2px solid gray;
    margin: 0;
    display: flex;
    align-items: center;
    font-family: Lato;
    font-size: 0.9rem;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    cursor: pointer; 
    
    
  }
  
  div.clickable p{
    margin-left: 1em;
    

  }
  div.clickable p span.less1{
    padding-left: 7.5em;
    
  }
  div.clickable p span.less2{
    position: relative;
    left: 21%;
    
  }
  div.clickable p span.less3{
   margin-left: 10.4em;
    
  }
  div.clickable p span.less4{
   margin-left: 9.8em;
    
  }
  div.clickable p span.less5{
    margin-left: 8.6em;
    
  }

  div.clickable:hover,
  div.clickable:focus
  
  {
    background: #111;
    color: white !important;

  }
  div.modal-heading{
    /* margin-left: 5em; */
    margin-top: 1em;
    font-size: 0.8rem;
    

  }
  div.modal-heading h2{
    margin-bottom: 0.3em;
    font-size: 1.5rem;
    
  }

  p.modal-bold{
    font-weight: 900;
    margin-bottom: 0.4em;
  }
  
  p.modal-yellow{
    width: 170px;
    height: 2.894px;
    background: #FAC710;
    margin-top: 0.7em;
    margin-bottom: 3em;
  }

  div.content-box{
    display: flex;
    margin-bottom: 0em;
    gap: 45px;
    justify-content: center;
    height: 55vh;
    margin-left: 3.5em;

  }
  div.content{
    width: 481px;
  }

  div.submitBtn{
    width: 200.092px;
    height: 6vh;
    padding: 11.435px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #111;
    margin: 0.2em auto;
    cursor: pointer;
    color: white;
    position: relative;
    top: 6vh;
  }

  div.submitBtn:hover,
  div.submitBtn:focus{
    background-color: #FAC710;
    color: #000;
  }

  form{
    color: #000;
    font-family: Lato;
    font-size: 0.9em;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    height: 347px;
  }

  form input.form-input{
    margin-top: 0.7em;
    width: 25em;
    height: 6vh;
    border-radius: 6.937px;
    border: 1.764px solid #111;
    padding-left: 1em;
  }

  form textarea.form-text{
    margin-top: 0.7em;
    width: 25em;
    border-radius: 6.937px;
    border: 1.764px solid #111;
    padding-left: 1em;
    height: 12vh;
    padding-top: 0.4em;
  }

  div.form1{
    margin-bottom: 1em;
  }
  
  div.form1 label{
    width: 403.417px;
    display: block;
  }
/* css for alert button */
  /* @keyframes wiggle {
    0% { transform: rotate(0deg); }
    25% { transform: rotate(5deg); }
    50% { transform: rotate(-5deg); }
    75% { transform: rotate(5deg); }
    100% { transform: rotate(0deg); }
  }
  
  .wiggle {
    animation-name: wiggle;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    
  }
  
  .wiggle.active {
    animation-play-state: running;
  } */

   div.wiggle{
    position: fixed;
    top: 30%;
    left: 20%;
    border-radius: 5px;
    width: 550px;
    background-color: rgb(255, 199, 39);
    border: 1px solid transparent;
    padding: 20px;
    box-shadow: rgba(0, 0, 0, 0.5) 0px 0px 10px;
    z-index: 100;
   }
   div.alertdiv{
    position: fixed;
    top: 30%;
    left: 20%;
    border-radius: 5px;
    width: 550px;
    background-color: rgb(255, 199, 39);
    border: 1px solid transparent;
    padding: 20px;
    box-shadow: rgba(0, 0, 0, 0.5) 0px 0px 10px;
    z-index: 100;
   }

  .alertbtn{
    background-color: transparent;
    color: black;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    padding: 10px;
    margin: 0px auto;
    width: 7em;
    cursor: pointer;
    border-color: transparent;
    position: relative;
    height: 0.5em;
    top: -230px;
    left: 250px;
    font-weight: 700;
  }

  /* .alertbtn:hover,
  .alertbtn:focus{
    background-color: #FAC710;
  } */
/* end of css for alert button */
/* @media all and (max-width: 900px)  {
  div.modal-heading {
   margin-left:4em;
  }
} */

div.form1-mobile{
  display: none;
}





/* mobile display  */

@media screen and (max-width: 500px) and (min-height: 932px)
{
  div.content{
    display: none;

  }
  div.modal-heading h2 {
    margin-bottom: 0.3em;
    font-size: 1rem;
}
  p.modal-bold {
  font-weight: 400;
  margin-bottom: 0.4em;
 }
 p.modal-not-bold{
  font-weight: 300;
  font-size: 0.7rem;
 }

 p.modal-yellow {
  width: 19vw;
  height: 2.894px;
  background: #FAC710;
  margin-top: 0.7em;
  margin-bottom: 3em;
}
div.modal-heading {
  margin-left: 0em !important;
}
div.clickable{
  width: 52vw;
  height: 4vh;
  font-size: 0.7rem;
  margin: 1em;
  padding:10px;
  margin-left: 0.5em;
}

div.clickable.dark{
  width: 52vw;
  height: 4vh;
  font-size: 0.7rem;
  margin: 1em;
  padding:10px;
  margin-left: 0.5em;
  background-color: #000;
  color:white;
}

div.side-bar{
 
  height: 55%;
  position: relative;
  left: -10%;
}

div.clickable.mobile3,
div.clickable.mobile4{
  display: none;
}
div.modal-content{
  height: unset !important;
}

div.submitBtn{
  
  height: 4vh;
  width: 40vw;
  margin-top: 1em;
  position: unset;

}
div.form1-mobile{
  display: unset;
  font-size: 0.7rem;
  width: 0;
  margin-left: 0em;
  height: 30vh;
}

form .form-input-mobile{
  width: 18em;
  height: 3vh;
  border-radius: 6.937px;
  border: 1.764px solid #111;
  padding-left: 1em;
  margin-top: 0.7em;
}

div.form1 label {
  width: 263.417px;
  display: block;
}

.form-text-mobile{
  margin-top: 0.7em;
  width: 18em;
  height: 8vh;
  border-radius: 6.937px;
  border: 1.764px solid #111;
  padding-left: 1em;
  padding-top: 0.4em;
}
div.content-box{
  
  height: unset;
  margin-left: 2.5em;
}
div.form1-mobile.except{
  height: 15vh;
}

div.alertdiv{
  left: -15%;
  width: 350px;
}

button.alertbtn{
  top: -170px;
  left: 150px;
}

.alert-info img{
  width: 50%;
}
.alert-text h2{
  font-size: 1em !important;
}
}
/* @media screen and (max-width: 360px) and (min-height: 740px) */
/* {
  div.content{
    display: none;

  }
  div.modal-heading h2 {
    margin-bottom: 0.3em;
    font-size: 1rem;
}
  p.modal-bold {
  font-weight: 400;
  margin-bottom: 0.4em;
 }
 p.modal-not-bold{
  font-weight: 300;
  font-size: 0.7rem;
 }

 p.modal-yellow {
  width: 19vw;
  height: 2.894px;
  background: #FAC710;
  margin-top: 0.7em;
  margin-bottom: 3em;
}
div.modal-heading {
  margin-left: 0em !important;
}
div.clickable{
  width: 52vw;
  height: 4vh;
  font-size: 0.7rem;
  margin: 1em;
  padding:10px;
  margin-left: 0.5em;
}

div.clickable.dark{
  width: 52vw;
  height: 4vh;
  font-size: 0.7rem;
  margin: 1em;
  padding:10px;
  margin-left: 0.5em;
  background-color: #000;
  color:white;
}

div.side-bar{
 
  height: 55%;
  position: relative;
  left: -10%;
}

div.clickable.mobile3,
div.clickable.mobile4{
  display: none;
}
div.modal-content{
  height: unset !important;
}

div.submitBtn{
  
  height: 4vh;
  width: 40vw;
  margin-top: 1em;
  position: unset;

}
div.form1-mobile{
  display: unset;
  font-size: 0.7rem;
  width: 0;
  margin-left: 0em;
  height: 30vh;
}

form .form-input-mobile{
  width: 18em;
  height: 3vh;
  border-radius: 6.937px;
  border: 1.764px solid #111;
  padding-left: 1em;
  margin-top: 0.7em;
}

div.form1 label {
  width: 263.417px;
  display: block;
}

.form-text-mobile{
  margin-top: 0.7em;
  width: 18em;
  height: 8vh;
  border-radius: 6.937px;
  border: 1.764px solid #111;
  padding-left: 1em;
  padding-top: 0.4em;
}
div.content-box{
  
  height: unset;
  margin-left: 2.5em;
}
div.form1-mobile.except{
  height: 15vh;
}

div.alertdiv{
  left: -15%;
  width: 350px;
}

button.alertbtn{
  top: -170px;
  left: 150px;
}

.alert-info img{
  width: 50%;
}
.alert-text h2{
  font-size: 1em !important;
}
} */
@media screen and (max-width: 375px) and (min-height: 667px),
screen and (max-width: 412px) and (min-height: 914px)

{
  div.content{
    display: none;
    

  }
  span.clsBtn{
    font-size: 1.5em;
  }
  div.modal-heading h2 {
    margin-bottom: 0.3em;
    font-size: 1rem;
}
  p.modal-bold {
  font-weight: 400;
  margin-bottom: 0.4em;
 }
 p.modal-not-bold{
  font-weight: 300;
  font-size: 0.7rem;
 }

 p.modal-yellow {
  width: 19vw;
  height: 2.894px !important;
  background: #FAC710;
  margin-top: 0.7em;
  margin-bottom: 3em;
}
div.modal-heading {
  margin-left: 0em !important;
}
div.clickable{
  width: 52vw;
  height: 4vh;
  font-size: 0.7rem;
  margin: 1em;
  padding:10px;
  margin-left: 0.5em;
}

div.clickable.dark{
  width: 52vw;
  height: 4vh;
  font-size: 0.7rem;
  margin: 1em;
  padding:10px;
  margin-left: 0.5em;
  background-color: #000;
  color:white;
}

div.side-bar{
 
  height: 55%;
  position: relative;
  left: -10%;
}

div.clickable.mobile3,
div.clickable.mobile4{
  display: none;
}
div.modal-content{
  height: unset !important;
  width: 75% !important;
  right: 13% !important;
}

div.submitBtn{
  
  height: 4vh;
  width: 40vw;
  margin-top: 1em;
  position: unset;

}
div.form1-mobile{
  display: unset;
  font-size: 0.7rem;
  width: 0;
  margin-left: 0em;
  height: 30vh;
}

form .form-input-mobile{
  width: 18em;
  height: 3vh;
  border-radius: 6.937px;
  border: 1.764px solid #111;
  padding-left: 1em;
  margin-top: 0.7em;
}

div.form1 label {
  width: 263.417px;
  display: block;
}

.form-text-mobile{
  margin-top: 0.7em;
  width: 18em;
  height: 8vh;
  border-radius: 6.937px;
  border: 1.764px solid #111;
  padding-left: 1em;
  padding-top: 0.4em;
}
div.content-box{
  
  height: unset;
  margin-left: 2.5em;
}
div.form1-mobile.except{
  height: 15vh;
}

div.alertdiv{
  left: -8%;
  width: 350px;
}

button.alertbtn{
  top: -170px;
  left: 150px;
}

.alert-info img{
  width: 50%;
}

.alert-text h2{
  font-size: 1em !important;
}
}

@media screen and (min-width: 414px) and (min-height: 869px)
{
  div.content{
    display: none;

  }

  span.clsBtn{
    font-size: 1.5em;
  }
  div.modal-heading h2 {
    margin-bottom: 0.3em;
    font-size: 1rem;
}
  p.modal-bold {
  font-weight: 400;
  margin-bottom: 0.4em;
 }
 p.modal-not-bold{
  font-weight: 300;
  font-size: 0.7rem;
 }

 p.modal-yellow {
  width: 19vw;
  height: 2.894px !important;
  background: #FAC710;
  margin-top: 0.7em;
  margin-bottom: 3em;
}
div.modal-heading {
  margin-left: 0em !important;
}
div.clickable{
  width: 52vw;
  height: 4vh;
  font-size: 0.7rem;
  margin: 1em;
  padding:10px;
  margin-left: 0.5em;
}

div.clickable.dark{
  width: 52vw;
  height: 4vh;
  font-size: 0.7rem;
  margin: 1em;
  padding:10px;
  margin-left: 0.5em;
  background-color: #000;
  color:white;
}

div.side-bar{
 
  height: 55%;
  position: relative;
  left: -10%;
}

div.clickable.mobile3,
div.clickable.mobile4{
  display: none;
}
div.modal-content{
  height: unset !important;
}

div.submitBtn{
  
  height: 4vh;
  width: 40vw;
  margin-top: 1em;
  position: unset;

}
div.form1-mobile{
  display: unset;
  font-size: 0.7rem;
  width: 0;
  margin-left: 0em;
  height: 30vh;
}

form .form-input-mobile{
  width: 18em;
  height: 3vh;
  border-radius: 6.937px;
  border: 1.764px solid #111;
  padding-left: 1em;
  margin-top: 0.7em;
}

div.form1 label {
  width: 263.417px;
  display: block;
}

.form-text-mobile{
  margin-top: 0.7em;
  width: 18em;
  height: 8vh;
  border-radius: 6.937px;
  border: 1.764px solid #111;
  padding-left: 1em;
  padding-top: 0.4em;
}
div.content-box{
  
  height: unset;
  margin-left: 2.5em;
}
div.form1-mobile.except{
  height: 15vh;
}

div.alertdiv{
  left: -12%;
  width: 350px;
}

button.alertbtn{
  top: -170px;
  left: 150px;
}

.alert-info img{
  width: 50%;
}

.alert-text h2{
  font-size: 1em !important;
}
}
@media screen and (max-width: 360px) and (min-height: 740px),
screen and (max-width:768px),
screen and (min-width: 390px) and (height: 844px)
{
  div.content{
    display: none;

  }

  span.clsBtn{
    font-size: 1.5em;
  }
  div.modal-heading h2 {
    margin-bottom: 0.3em;
    font-size: 1rem;
}
  p.modal-bold {
  font-weight: 400;
  margin-bottom: 0.4em;
 }
 p.modal-not-bold{
  font-weight: 300;
  font-size: 0.7rem;
 }

 p.modal-yellow {
  width: 19vw;
  height: 2.894px !important;
  background: #FAC710;
  margin-top: 0.7em;
  margin-bottom: 3em;
}
div.modal-heading {
  margin-left: 0em !important;
}
div.clickable{
  width: 59vw;
  height: 4vh;
  font-size: 0.7rem;
  margin: 1em;
  padding:10px;
  margin-left: 0.5em;
}

div.clickable.dark{
  width: 59vw;
  height: 4vh;
  font-size: 0.7rem;
  margin: 1em;
  padding:10px;
  margin-left: 0.5em;
  background-color: #000;
  color:white;
}

div.side-bar{
 
  height: 55%;
  position: relative;
  left: -10%;
}

div.clickable.mobile3,
div.clickable.mobile4{
  display: none;
}
div.modal-content{
  height: unset !important;
  width: 75% !important;
  margin-left: 24% !important;
  /* right: 12% !important; */
}

div.submitBtn{
  
  height: 4vh;
  width: 40vw;
  margin-top: 1em;
  position: unset;

}
div.form1-mobile{
  display: unset;
  font-size: 0.7rem;
  width: 0;
  margin-left: 0em;
  height: 30vh;
}

form .form-input-mobile{
  width: 18em;
  height: 3vh;
  border-radius: 6.937px;
  border: 1.764px solid #111;
  padding-left: 1em;
  margin-top: 0.7em;
}

div.form1 label {
  width: 263.417px;
  display: block;
}

.form-text-mobile{
  margin-top: 0.4em;
  width: 18em;
  height: 8vh;
  border-radius: 6.937px;
  border: 1.764px solid #111;
  padding-left: 1em;
  padding-top: 0.4em;
}
div.content-box{
  
  height: unset;
  margin-left: 2.5em;
}
div.form1-mobile.except{
  height: 15vh;
}

div.alertdiv{
  left: -12%;
  width: 350px;
}

button.alertbtn{
  top: -170px;
  left: 150px;
}

.alert-info img{
  width: 50%;
}

.alert-text h2{
  font-size: 1em !important;
}
}

.clickable.dark{
  background-color: #000;
  width: 290px;
  height: 50px;
  border: 2px solid gray;
  margin: 0;
  display: flex;
  align-items: center;
  font-family: Lato;
  font-size: 0.9rem;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  cursor: pointer; 
  color: white;
}

.main .mybread .details{
  height: 40px !important;
}



@media screen and (min-width: 1210px) and (min-height: 568px){
  div.modal-heading {
    margin-left: 4em;

}
div.modal-content{
  height: 96.4vh;
  /* right: 15%;  */
  top: 2%;
  margin-left: 24% !important;
}

div.side-bar {
  margin: 0;
  margin-top: 0;
}
}

@media screen and (max-width: 1568px) and (min-height: 672px){

div.modal-content {
  background-color: #fefefe;
  position: absolute;
  height: 88.5vh;
  right: 0;
  padding: 15px;
  border: 1px solid #888;
  width: 70%;
  top: 5%;
  /* right: 16%; */
  animation: slide-in 0.5s ease-in-out forwards;
  margin-left: 24% !important;
}



div.side-bar{
  margin: 0; 
  margin-top: 0;
}

div.modal-heading {
  margin-left: 9em;
}

div.modal-content {
  height: 93.5vh;
  width: 67%;
  top: 3%;
  margin-left: 24% !important;
}

}



